<template>
  <v-card :loading="isLoading">
    <v-card-title
      class="headline primary lighten-1 white--text justify-space-between"
    >
      <span v-if="item">
        {{
          $t("recommender.editSettingsFor", {
            scope: getServiceName({ scope }),
          })
        }}
      </span>
      <span v-else>
        {{
          $t("recommender.addSettingsFor", {
            scope: getServiceName({ scope }),
          })
        }}
      </span>
      <v-btn icon color="white" :title="$t('close')" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <div class="px-3" style="position: relative">
      <v-overlay
        :absolute="true"
        :value="dataLoading"
        justify-center
        align-center
        opacity="0.15"
      >
        <v-progress-circular size="64" indeterminate></v-progress-circular>
      </v-overlay>
      <v-card-text>
        <div v-if="item && item.base">
          <span class="subtitle-1 pb-3 font-weight-bold">
            {{ $t("recommender.segmentSettings") }}:
          </span>
          {{ $t("recommender.allAccounts") }}
        </div>
        <div v-else>
          <div class="subtitle-1 pb-3 font-weight-bold">
            {{ $t("recommender.segmentSettings") }}
          </div>
          <v-row>
            <v-col cols="4">
              <v-autocomplete
                :clearable="true"
                :items="availableAccountTypeLabels"
                item-text="title"
                item-value="id"
                menu-props="closeOnContentClick"
                deletable-chips
                multiple
                small-chips
                hide-no-data
                hide-selected
                name="type"
                :label="attributeLabels.type"
                v-model="settings.segment.type"
              ></v-autocomplete>
            </v-col>
            <v-col cols="8">
              <v-autocomplete
                :clearable="true"
                :items="tags"
                item-text="title"
                item-value="id"
                :loading="isTagLoading"
                :search-input.sync="tagSearch"
                menu-props="closeOnContentClick"
                @change="tagSearch = ''"
                deletable-chips
                multiple
                small-chips
                hide-no-data
                hide-selected
                name="tags"
                :label="attributeLabels.tagId"
                v-model="settings.segment.tagId"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                :clearable="true"
                :items="availableStages"
                :label="attributeLabels.stage"
                v-model="settings.segment.stage"
                menu-props="closeOnContentClick"
                deletable-chips
                multiple
                small-chips
                hide-no-data
                hide-selected
                name="language"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                :clearable="true"
                item-text="key"
                item-value="gender"
                :items="availableGenders"
                :label="this.attributeLabels.gender"
                v-model="settings.segment.gender"
                menu-props="closeOnContentClick"
                deletable-chips
                multiple
                small-chips
                hide-no-data
                hide-selected
                name="gender"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                :clearable="true"
                :items="availableAges"
                :label="this.attributeLabels.ageGroup"
                v-model="settings.segment.ageGroup"
                menu-props="closeOnContentClick"
                deletable-chips
                multiple
                small-chips
                hide-no-data
                hide-selected
                name="ageGroup"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                :clearable="true"
                item-text="label"
                item-value="key"
                :items="availableLanguageLabels"
                :label="this.attributeLabels.language"
                v-model="settings.segment.language"
                menu-props="closeOnContentClick"
                deletable-chips
                multiple
                small-chips
                hide-no-data
                hide-selected
                name="language"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>
        <div class="mt-5">
          <div class="subtitle-1 mb-3 font-weight-bold">
            {{ $t("recommender.limitSettings") }}
          </div>
          <div>
            <v-row>
              <v-col></v-col>
              <v-col class="text-center">
                {{ $t("recommender.period.post") }}
              </v-col>
              <v-col class="text-center">
                {{ $t("recommender.period.day") }}
              </v-col>
            </v-row>
            <v-row v-for="(action, i) in availableActions(this.scope)" :key="i">
              <v-col class="align-self-center">
                {{ $t("recommender.action." + action) }}
              </v-col>
              <v-col>
                <v-text-field
                  :name="action + '_post'"
                  label=""
                  v-model="settings.limits.post[action]"
                  type="text"
                  class="text-center"
                  :error-messages="settingsErrors.limits.post[action]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  :name="action + '_day'"
                  label=""
                  v-model="settings.limits.day[action]"
                  :error-messages="settingsErrors.limits.day[action]"
                  type="text"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-alert v-if="isSegmentExist" dense outlined type="error">
          {{ $t("recommender.segmentExist") }}
        </v-alert>
        <v-alert v-if="isLimitEmpty" dense outlined type="error">
          {{ $t("recommender.limitsRequired") }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-checkbox
          v-if="!item || !item.base"
          name="enabled"
          :label="attributeLabels.status"
          v-model="settings.status"
          true-value="active"
          false-value="inactive"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="$emit('close')">
          {{ $t("close") }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="updateItem">
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import _ from "lodash";

import { mapGetters } from "vuex";

import validator from "@/mixin/validator";
import { numeric, required } from "vuelidate/lib/validators";

import { PATCH_AUDIENCE_SETTINGS } from "@/store/actions/recommender";
import { FETCH_TAGS_PARTIAL } from "@/store/actions/tag";

import { TYPE_CONTENT } from "@/store/modules/tag/getters";
import { FETCH_ACCOUNT_TYPES } from "@/store/actions/donor";

export default {
  name: "AudienceRecommenderForm",
  mixins: [validator],
  props: {
    item: Object,
    segment: Object,
    scope: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modal: 0,
      dataLoading: false,
      formErrors: null,
      settings: this.item
        ? {
            target: null,
            segment: this.item.segment,
            limits: {
              day: {},
              post: {},
            },
            status: this.item.status,
          }
        : {
            target: this.scope,
            segment: this.segment,
            limits: {
              day: {},
              post: {},
            },
            status: "inactive",
          },
      attributeLabels: {
        gender: this.$t("account.attribute.gender"),
        type: this.$t("account.attribute.type"),
        ageGroup: this.$t("account.attribute.age"),
        language: this.$t("account.attribute.language"),
        tagId: this.$t("account.attribute.tags"),
        stage: this.$t("account.attribute.stage"),
        status: this.$t("recommender.settingsStatus"),
      },
      //Filter values status
      isTagLoading: false,
      //Filter values
      tags: [],
      //Filter entered value
      tagSearch: null,
      availableAccountTypeLabels: [],
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters("audience", [
      "availableScopes",
      "availableActions",
      "availableSegments",
    ]),
    ...mapGetters("account/donor", [
      "availableGenders",
      "availableLanguages",
      "availableStages",
      "availableAges",
      "availableLanguageLabels",
    ]),
    ...mapGetters("service", ["getServiceName"]),
    settingsErrors: function () {
      return _.merge(
        {
          limits: {
            day: {},
            post: {},
          },
        },
        this.validator.errors.settings
      );
    },
    availableSegmentLabels: function () {
      let component = this;
      let result = [];
      this.availableSegments.forEach(function (segment) {
        result.push({
          key: segment,
          label: _.get(component.attributeLabels, segment),
        });
      });
      return result;
    },
    formattedSegment: function () {
      let segment = {};
      _.forIn(this.settings.segment, function (value, key) {
        if (value.length) {
          segment[key] = value;
        }
      });
      return segment;
    },
    hasFormattedSegment: function () {
      return !this.isEmpty(this.formattedSegment);
    },
    limitValidation: function () {
      const baseRules =
        this.item && this.item.base ? { numeric, required } : { numeric };
      let rules = {};
      let component = this;
      let availableActions = component.availableActions(component.scope);
      _.forIn(component.settings.limits, function (value, key) {
        let subRules = {};
        availableActions.forEach(function (action) {
          subRules[action] = baseRules;
        });
        rules[key] = subRules;
      });
      return rules;
    },
    isSegmentExist: function () {
      return (
        this.formErrors &&
        this.formErrors.segment &&
        this.formErrors.segment.indexOf("duplicate_segment") > -1
      );
    },
    isLimitEmpty: function () {
      return (
        this.formErrors &&
        this.formErrors.limits &&
        this.formErrors.limits.indexOf("required") > -1
      );
    },
  },
  watch: {
    scope: function () {
      this.dispatchLimits();
    },
    tagSearch: function (value) {
      this.isTagLoading = true;
      this.$store
        .dispatch("tag/" + FETCH_TAGS_PARTIAL, {
          limit: 50,
          offset: 0,
          search: {
            title: value,
            level: 1,
            selectedIds: this.settings.segment.tagId,
            excludeType: [TYPE_CONTENT],
          },
        })
        .then(
          (payload) => {
            this.isTagLoading = false;
            this.tags = payload.data.batch;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
  methods: {
    updateItem: function () {
      if (this.validate()) {
        this.dataLoading = true;
        let { settings } = this;
        settings.target = this.scope;
        this.$store
          .dispatch("audience/" + PATCH_AUDIENCE_SETTINGS, {
            settings,
            id: this.item ? this.item.id : null,
          })
          .then(
            (payload) => {
              this.dataLoading = false;
              if (payload.status) {
                this.$emit("submitted");
              } else {
                this.formErrors = payload.errors;
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
    dispatchLimits: function () {
      let component = this;
      let availableActions = component.availableActions(component.scope);
      _.forIn(component.settings.limits, function (value, key) {
        let limits = {};
        availableActions.forEach(function (action) {
          limits[action] = _.get(
            component.item,
            ["limits", key, _.camelCase(action)],
            null
          );
        });
        component.settings.limits[key] = limits;
      });
    },
    isEmpty: function (value) {
      return _.isEmpty(value);
    },
    fetchAccountTypes: function () {
      this.isLoading = true;
      this.$store
        .dispatch("account/donor/" + FETCH_ACCOUNT_TYPES, {
          limit: 50,
          offset: 0,
        })
        .then(
          (payload) => {
            this.isLoading = false;
            this.availableAccountTypeLabels = payload.data;
          },
          () => {}
        )
        .catch(() => {});
    },
  },
  mounted: function () {
    this.dispatchLimits();
    this.fetchAccountTypes();
    this.isTagLoading = true;
    this.$store
      .dispatch("tag/" + FETCH_TAGS_PARTIAL, {
        limit: 50,
        offset: 0,
        search: {
          level: 1,
          selectedIds: this.settings.segment.tagId,
          excludeType: [TYPE_CONTENT],
        },
      })
      .then(
        (payload) => {
          this.isTagLoading = false;
          this.tags = payload.data.batch;
        },
        (err) => {
          console.log("Err", err);
        }
      )
      .catch(() => {});
  },
  validations: function () {
    return {
      settings: {
        limits: this.limitValidation,
      },
    };
  },
};
</script>

<style scoped>
.row + .row,
.col,
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 0;
  padding-bottom: 0;
}

.row + .row {
  margin-top: 0;
}
</style>
